<template>
  <div>
    <div class="header-container">
      <div class="header-content">
        <div class="header-title ui huge header bold">Risk Assessment Tool</div>
      </div>
      <div class="header-action">
        <button @click="goHome" class="ui icon mini button">
          <i class="home icon"></i>
        </button>
        <button @click="signOut" class="ui blue mini button">
          <i class="sign-out icon"></i>
        </button>
      </div>
    </div>
    <div class="container">
      <div class="header">Campaign Type</div>
      <div class="ui centered grid">
        <div class="center aligned column">
          <div class="ui checkbox field" v-for="type in ['Paid', 'Influencer']" :key="type">

            <input type="checkbox" :value="type" :checked="isChecked(type)" @change="updateSelection(type, $event)">
            <label>{{ type }}</label>
          </div>
        </div>
      </div>
      <div class="actions">
        <button class="ui basic button" @click="returnCampaignGeneralScope">Cancel</button>
        <button class="ui primary button" @click="next">Next</button>
      </div>
    </div>
  </div>
</template>




<script>
import { Auth } from 'aws-amplify';
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      selectedCampaignTypes: []
    }
  },
  methods: {
    ...mapMutations(['SET_SELECTED_CAMPAIGN_TYPES']),
    isChecked(value) {
      return this.$store.state.campaign.selectedCampaignTypes.includes(value);
    },
    updateSelection(value, event) {
      let updatedSelection = [...this.$store.state.campaign.selectedCampaignTypes];
      if (event.target.checked) {
        updatedSelection.push(value);
      } else {
        updatedSelection = updatedSelection.filter(item => item !== value);
      }
      this.SET_SELECTED_CAMPAIGN_TYPES(updatedSelection);
    },
    next() {
      const CAMPAIGN_QUESTION_ORDERING = ['Paid', 'Influencer', 'Organic'];
      const selectedCampaignTypes = this.$store.state.campaign.selectedCampaignTypes;
      console.log(selectedCampaignTypes);
      if (selectedCampaignTypes.length === 0) {
        alert("Select Specific Campaign Type");
      } else {
        console.log('hit else function');
        for (let i = 0; i < CAMPAIGN_QUESTION_ORDERING.length; i++) {
          console.log(CAMPAIGN_QUESTION_ORDERING[i]);
          if (selectedCampaignTypes.includes(CAMPAIGN_QUESTION_ORDERING[i])) {
            console.log('hit');
            console.log(CAMPAIGN_QUESTION_ORDERING[i]);
            let newRoute = CAMPAIGN_QUESTION_ORDERING[i].toLowerCase();
            this.$router.push(`/${newRoute}`)
            return;
          }
        }
        this.$router.push('/test-and-learn')
      }


    },
    returnCampaignGeneralScope() {
      this.$router.push({ name: 'CampaignGeneralScope' });
    },
    goHome() {
      this.$router.push({ name: 'CampaignDashboard' });
    },
    async signOut() {
      console.log('signoutmethod reached');
      try {
        this.$store.commit('clearSuccessMessage');
        await Auth.signOut();
        await this.$store.dispatch('resetCampaignState');  
        this.$router.push({ name: 'CampaignDashboard' });  
      } catch (error) {
        console.log("Sign Out Failed", error);
      }
    },
  }
}

</script>
  
<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f3f3f3;
  margin-top: 0px;
  margin-bottom: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.ui.checkbox.field {
  display: flex;
  margin-bottom: 20px;
}

.ui.checkbox.field label {
  width: 150px;
  line-height: 20px;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}
</style>