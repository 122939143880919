<template>
    <div class="ui modal" v-show="show">
        <i class="close icon" @click="close"></i>
        <div class="header">
            Denial Confirmation
        </div>
        <div class="content">
            <div class="description">
                <div class="ui form">
                    <div class="field">
                        <label>Denial Reason</label>
                        <textarea rows="2" v-model="comment"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui black deny button" @click="close">
                Cancel
            </div>
            <div class="ui positive right labeled icon button" @click="submit">
                Confirm
                <i class="checkmark icon"></i>
            </div>
        </div>
    </div>
</template>
  
<script>
import $ from 'jquery'

export default {
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            comment: ''
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        submit() {
            this.$emit('submit', this.comment);
            console.log("comment successfully emitted.")
        }
    },
    watch: {
        show(newVal) {
            if (newVal) {
                $(this.$el).modal('show');
            } else {
                $(this.$el).modal('hide');
            }
        }
    },
    mounted() {
        $(this.$el)
            .modal({
                onHide: () => {
                    this.$emit('close');
                }
            });
    }
}
</script>
  
<style scoped>
</style>
  