<template>
    <div v-if="isLoading" class="loading-cover">
        <div class="ui active dimmer" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000;">
            <div class="ui text loader">Loading</div>
        </div>
    </div>
    <div v-else>
        <div class="header-container">
            <div class="header-content">
                <div class="header-title ui huge header bold">Risk Assessment Tool</div>
            </div>
            <div class="header-action">
                <button @click="goHome" class="ui icon mini button">
                    <i class="home icon"></i>
                </button>
                <button @click="signOut" class="ui blue mini button">
                    <i class="sign-out icon"></i>
                </button>
            </div>
        </div>
        <div class="ui container" style="margin-top: 30px; max-width: 1000px;">
            <div class="ui grid stackable">
                <div class="sixteen wide column">
                    <div v-if="campaignInfo">
                        <div class="ui card fluid">
                            <div class="content">
                                <h2 class="ui header">Campaign Summary</h2>
                            </div>
                            <div class="content" style="word-break: break-word;">
                                <div class="header">{{ campaignInfo.name }}</div>
                                <p>Created By: {{ campaignInfo.created_by }}</p>
                            </div>
                        </div>
                        <div v-for="(campaignType, index) in campaignInfo.response" :key="index"
                            class="ui card fluid">
                            <div class="content" style="word-break: break-word;">
                                <div class="header">{{ campaignType.campaign_type }}</div>
                                <ul class="ui list custom-list">
                                    <li v-for="response in campaignType.response" :key="response.Question">
                                        <b>{{ response.Question }}: </b> {{ response.Value }}
                                    </li>
                                </ul>
                            </div>
                            <div class="content">
                                <div class="media-gallery-container">
                                    <div v-for="(url, urlIndex) in campaignInfo[`${campaignType.campaign_type.toLowerCase()}MediaKeysUrls`]"
                                        :key="`media-${campaignType.campaign_type}-${urlIndex}`"
                                        class="media-card">
                                    <img v-if="isImageUrl(url)" :src="url" :alt="`Media for ${campaignType.campaign_type} ${urlIndex}`" />
                                    <video v-if="isVideoUrl(url)" controls>
                                        <source :src="url" type="video/mp4"> <!-- Assuming all videos are mp4 for simplicity -->
                                        Your browser does not support the video tag.
                                    </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sixteen wide column">
                    <h2 class="ui header">
                    Campaign History
                    <div class="sub header">View historic changes and comments to the campaign.</div>
                    </h2>
                    <table class="ui celled table">
                        <thead>
                        <tr>
                            <th>Individual</th>
                            <th>Date</th>
                            <th>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="action in pastActions" :key="action.id">
                            <td>{{ action.action_by_name }}</td>
                            <td>{{ formatDate(action.updated_on) }}</td>
                            <td>{{ action.comment }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
            </div>
            <div class="actions" style="margin-top: 20px" >
                <button class="ui negative basic button" @click="previousPage">Cancel</button>
                <button v-if="role === 'approvers'" class="ui red basic button" @click="denyCampaign">Deny</button>
                <button v-if="role === 'approvers'" class="ui green basic button" @click="acceptCampaign">Accept</button>
            </div>
            <EmailModal v-model="showModal" :emails="emails" @close="closeEmailModal" @submit="handleEmailSubmit" />
            <DenialModal :show="showDenialModal" @close="closeDenialModal" @submit="handleDenial" />
        </div>
    </div>
</template>




<script>
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../helper.js';
import EmailModal from './AcceptModal.vue'
import DenialModal from './DenialModal.vue'
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery'
window.$ = window.jQuery = $;

export default {
    data() {
        return {
            campaignId: null,
            role: '',
            campaignInfo: {},
            showModal: false,
            showDenialModal: false,
            emails: ['', '', ''],
            pastDenialReasons: [],
            isLoading: true
        }
    },
    components: {
        EmailModal,
        DenialModal
    },
    async mounted() {
        this.isLoading = true;

        const campaignIdFromRoute = this.$route.params.campaignId;
        if (campaignIdFromRoute) {
            this.campaignId = campaignIdFromRoute;
            localStorage.setItem('campaignId', campaignIdFromRoute);
        } else {
            this.campaignId = localStorage.getItem('campaignId');
        }
        console.log('campaignId that will be used: ' + this.campaignId);

        await Promise.all([
            this.fetchCampaignData(),
            this.fetchActions()
        ]);

        let user = await Auth.currentAuthenticatedUser();
        this.role = user.signInUserSession.idToken.payload['cognito:groups']?.[0];
        if (!this.role || this.role != 'approvers') {
            this.role = 'campaignPlanner'
        }
        this.isLoading = false;

        import('semantic-ui-css/semantic.min.js').then(() => {
            $(this.$refs.modal).modal({
                closable: false,
                onApprove: () => {
                    this.close()
                    return false
                }
            })
            $(this.$refs.modal).css('display', 'block');
        })
    },
    methods: {
        closeEmailModal() {
            console.log('close email modal was hit')
            this.showModal = false;
        },
        closeDenialModal() {
            this.showDenialModal = false;
        },
        formatDate(unixTimestamp) {
            return moment.unix(unixTimestamp).format('MM/DD/YYYY');
        },
        isImageUrl(url) {
            console.log(url)
            return /\.(jpg|jpeg|png|gif)$/i.test(url.split('?')[0]);
        },
        isVideoUrl(url) {
            console.log(/\.(mp4|webm|ogg|mov|m4v)$/i.test(url.split('?')[0]))
            return /\.(mp4|webm|ogg|mov|m4v)$/i.test(url.split('?')[0]);
        },
        async fetchCampaignData() {
            this.isLoading = true;
            this.campaignInfo = null

            const user = await Auth.currentAuthenticatedUser();
            const userToken = user.signInUserSession.idToken.jwtToken;
            const endPoint = `${BASE_URL}campaigns/` + this.campaignId;

            try {
                const response = await fetch(endPoint, {
                    headers: {
                        'Authorization': userToken,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('Received data:', data);

                this.campaignInfo = data;
                console.log('Updated campaignInfo:', this.campaignInfo);
                for (const fileType of ['paidMediaKeys', 'influencerMediaKeys', 'organicMediaKeys']) {
                    if (this.campaignInfo[fileType] && this.campaignInfo[fileType].length > 0) {
                    const preSignedUrls = await this.getMediaPresignedUrls(this.campaignInfo[fileType]);
                    this.campaignInfo[`${fileType}Urls`] = preSignedUrls;
                    } else {
                    this.campaignInfo[`${fileType}Urls`] = {};
                    }
                }
            } catch (error) {
                console.error('Error fetching campaign data:', error);
            } finally {
                this.isLoading = false;
                console.log('Updated campaignInfo after preSignedURLS', this.campaignInfo);
            }
        },
        async getMediaPresignedUrls(mediaKeys) {
            console.log("getMediaPresign is entered")
            const user = await Auth.currentAuthenticatedUser();
            const userToken = user.signInUserSession.idToken.jwtToken;
            const queryString = `mediaKeys=${encodeURIComponent(mediaKeys.join(','))}`;
            const presignedUrlEndPoint = `${BASE_URL}campaigns/media-presigned-url?${queryString}`;
            try {
                const response = await axios.get(presignedUrlEndPoint, {
                    headers: { 'Authorization': userToken },
                });

                if (response.status === 200) {
                    const responseBody = JSON.parse(response.data.body);
                    console.log('Received pre-signed URLs:', responseBody.preSignedUrls);
                    return responseBody.preSignedUrls; 
                } else {
                    console.error(`Failed to retrieve pre-signed URLs: ${response.status}`);
                    return {};
                }
                } catch (error) {
                console.error('Error while getting pre-signed URLs:', error);
                return {};
                }
        },
        extractEmails() {
            let emails = [];

            if (Array.isArray(this.campaignInfo.response)) {
                this.campaignInfo.response.forEach((item) => {
                    if (item.Question === 'Agency Email(s)') {
                        let emailArray = item.Value.split(',');
                        emails.push(...emailArray);
                    }
                });
            } else {
                console.error('campaignInfo.response is not an array or is not defined');
            }

            if (emails.length === 0) {
                emails.push('');
            }

            return emails;
        },
        acceptCampaign() {
            this.emails = this.extractEmails();
            console.log(this.emails);
            this.showModal = true;
        },
        handleModalClose() {
            this.$refs.emailModal.close();
            this.showModal = false;
        },
        handleEmailSubmit(newEmails) {
            this.isLoading = true;
            console.log('handle Email Submit called')
            console.log(newEmails);
            this.emails = newEmails;
            this.closeEmailModal();
            this.handleSubmit(this.emails);

        },
        async handleSubmit(data) {
            const { emails, comment } = data;
            const user = await Auth.currentAuthenticatedUser();
            const userToken = user.signInUserSession.idToken.jwtToken;
            console.log(user);
            console.log(emails);
            let headers = {
                'Authorization': userToken,
                'Content-Type': 'application/json'
            }
            console.log(this.campaignId);
            const requestBody = {
                emails, 
                comment,
                id: this.campaignId
            };
            console.log("REQUST BODY IS" + requestBody);

            try {
                let response = await axios.post(`${BASE_URL}campaigns/approve`, JSON.stringify(requestBody), { headers: headers });
                console.log(response.data)
                this.$store.commit('setSuccessMessage', 'The campaign was approved successfully.');
                setTimeout(() => {
                    this.$store.commit('clearSuccessMessage');
                }, 3000);
                this.$router.push({ name: 'CampaignDashboard' });
            } catch (error) {
                console.log('error', error)
            }
        },
        async denyCampaign() {
            this.showDenialModal = true;
            console.log(this.showDenialModal);
        },
        async handleDenial(comment) {
            this.isLoading = true;
            const user = await Auth.currentAuthenticatedUser().catch(err => {
                console.error('Error getting current user:', err);
            });
            console.log("DENIAL COMMENT IS")
            console.log(comment);
            const userToken = user.signInUserSession.idToken.jwtToken;

            let requestBody = {
                id: this.campaignId,
                comment: comment
            }

            let url = `${BASE_URL}campaigns/deny`

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userToken, 
                },
                body: JSON.stringify(requestBody),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    this.closeDenialModal();
                    this.isLoading = false;
                    this.$store.commit('setSuccessMessage', 'The campaign was denied successfully.');
                    setTimeout(() => {
                        this.$store.commit('clearSuccessMessage');
                    }, 3000);
                    this.$router.push({ name: 'CampaignDashboard' });
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        async fetchActions() {
            const user = await Auth.currentAuthenticatedUser();
            const userToken = user.signInUserSession.idToken.jwtToken;
            const endPoint = `${BASE_URL}actions/${this.campaignId}`;

            try {
                const response = await axios.get(endPoint, {
                    headers: {
                        'Authorization': userToken,
                    },
                });

                if (!response.data || !response.data.actions) {
                    throw new Error('No actions data received.');
                }
                
                this.pastActions = response.data.actions;
            } catch (error) {
                console.error('error fetching actions data:', error);
            }
        },
        previousPage() {
            this.$router.push('/');
        },
        goHome() {
            this.$router.push({ name: 'CampaignDashboard' });
        },
        async signOut() {
            console.log('signoutmethod reached');
            try {
                this.$store.commit('clearSuccessMessage');
                await Auth.signOut();
                await this.$store.dispatch('resetCampaignState');  
                this.$router.push({ name: 'CampaignDashboard' });  
            } catch (error) {
                console.log("Sign Out Failed", error);
            }
        },
    },

};
</script>

<style scoped>
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f3f3f3;
    margin-top: 0px;
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

ul.custom-list {
    list-style-type: none !important;
    padding-left: 0 !important;
}
ul.custom-list li {
    text-align: left !important;
}

.media-gallery-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
}
.media-card {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  flex: 0 0 auto;
  width: 200px; 
  margin-right: 10px;
  background: #fff; 
  padding: 10px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.media-card img, .media-card video {
  width: 100%;
  height: auto;
  max-height: 200px;
}
</style>
