<script setup>
/* eslint-disable */
  import { Authenticator } from '@aws-amplify/ui-vue';
  import { useStore } from 'vuex';
  import '@aws-amplify/ui-vue/styles.css';

  const store = useStore();

  const updateUser = (user) => {
  store.commit('SET_USER', user);
};

</script>

<template>
  <authenticator @user-logged-in="updateUser">
    <template v-slot="{ user, signOut }">
<!--      <div class="container">-->
      <router-view />
<!--      </div>-->
    </template>
  </authenticator>
</template>

<script>
/* eslint-disable */
import CampaignDashboard from './components/CampaignDashboard.vue';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';


Amplify.configure(awsconfig);



export default {
  name: 'App',
  components: {
    CampaignDashboard
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
.container {
    margin: auto;
    width: 80%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
</style>
