<template>
  <div>
    <div class="header-container">
      <div class="header-content">
        <img :src="Logo" alt="MARS Logo" class="mars-logo" /> 
        <div class="header-title ui huge header">Risk Assessment Tool</div>
      </div>
      <div class="header-action">
        <div class="user-and-logout">
          <div class="campaign-button" v-if="role === 'campaignPlanner'">
            <router-link to="general-scope">
              <button @click="resetStore" class="ui blue mini button new-campaign-button">New Campaign</button>
            </router-link>
          </div>
          <div class="campaign-button" v-else-if="role === 'approvers'">
            <button @click="goToEditor" class="ui green mini button">Edit Questions</button>
          </div>
          <div class="ui mini left labeled button">
            <div class="ui basic label">
              {{ user && user.attributes ? user.attributes.name : 'Loading Campaigns...' }}
            </div>
            <button class="ui mini icon button" @click="signOut">
              <i class="sign out alternate icon"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="ui blue message">
        <div class="header">MARS Brand Safety SteerCo</div>
        <p v-if="!isEditing">
          {{ editableText }}
        </p>
        <div v-if="role === 'approvers' && isEditing" class="ui input fluid">
          <input type="text" v-model="editableText">
        </div>
        <button v-if="role === 'approvers'" @click="toggleEdit" class="ui blue basic icon mini button edit-message" style="opacity: 0.5; margin-top: 5px;">
          <i :class="isEditing ? 'save icon' : 'edit icon'"></i>
        </button>
    </div>
    <div class="ui success message" v-if="successMessage">
      <i class="close icon" @click="closeSuccessMessage"></i>
      <div class="header">{{ successMessage }}</div>
    </div>
    <table class="ui fixed single line striped celled table">
      <thead>
      <tr>
        <th>Campaign Name</th>
        <th>Planner</th>
        <th style="width: 10%; text-align: center; overflow: visible">
          <div ref="statusDropdown" class="ui fluid search selection dropdown" @change="updateSelectedStatus">
            <input type="hidden" name="status">
            <i class="dropdown icon"></i>
            <div class="default text">Status</div>
            <div class="menu">
              <div class="item" data-value="">All</div>
              <div class="item" data-value="Pending">Pending</div>
              <div class="item" data-value="Approved">Approved</div>
              <div class="item" data-value="Denied">Denied</div>
            </div>
          </div>
        </th>
        <th style="width: 10%;"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="loading">
        <td colspan="4">
          <div class="ui segment">
            <div class="ui active dimmer">
              <div class="ui loader"></div>
            </div>
          </div>
        </td>
      </tr>
      <tr v-else v-for="campaign in filteredCampaigns" :key="campaign.id">
        <td>{{ campaign.name }}</td>
        <td>{{ campaign.created_by }}</td>
        <td style="text-align: center;" class="aligned">
          <div class="ui label" :class="getStatusClass(campaign.status)">{{ campaign.status }}</div>
        </td>
        <td style="text-align: center;">
          <div class="ui icon basic buttons">
            <a v-if="role !== 'approvers'" class="ui mini button circular" @click="goToReview(campaign.id)">
              <i class="edit icon"></i>
            </a>
            <a class="ui mini button circular" @click="goToCampaignDetails(campaign.id)">
              <i class="arrow right icon"></i>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button @click="fetchPreviousPage" class="ui icon button" :disabled="currentPage === 1">
        <i class="left arrow icon"></i>
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="fetchNextPage" class="ui icon button" :disabled="!lastEvaluatedKey || currentPage >= totalPages">
        <i class="right arrow icon"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../helper.js';
// eslint-disable-next-line
import Logo from '@/assets/mars.png';
export default {
  name: 'CampaignDashboard',
  data() {
    return {
      user: {},
      role: '',
      campaigns: [],
      isEditing: false,
      editableText: 'Loading...',
      isNext: true,
      loading: false,
      selectedStatus: '',
      lastEvaluatedKey: null,
      currentPage: 1,
      totalItems: 0,
      itemsPerPage:10,
      currentLastEvaluatedKey: null,
      previousLastEvaluatedKeys: [],
      Logo: require('@/assets/mars.png')     
    };
  },
  computed: {
    ...mapState(['successMessage']),
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    filteredCampaigns() {
      return this.campaigns;
    }

  },
  watch: {
    successMessage: {
      handler(newVal) {
        console.log("Watcher triggered with value: ", newVal);
        if (newVal) {
          setTimeout(() => {
            this.$store.commit('clearSuccessMessage');
          }, 3000);
        }
      },
      immediate: false,
    },
  },
  methods: {
    ...mapMutations(['clearSuccessMessage']),
    ...mapActions(['resetCampaignState']),
    async fetchData() {
      this.loading = true;

      try {
        const params = new URLSearchParams();
        params.append("limit", this.itemsPerPage.toString());

        //if status
        if (this.selectedStatus) {
          params.append("status", this.selectedStatus);
        }

        if (this.currentPage > 1 && this.previousLastEvaluatedKeys.length >= this.currentPage - 1) {
          params.append("exclusiveStartKey", this.previousLastEvaluatedKeys[this.currentPage - 2]);
        }

        const response = await fetch(`${BASE_URL}campaigns?${params.toString()}`, {
          headers: {
            'Authorization': this.user.signInUserSession.idToken.jwtToken
          }
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();

        this.campaigns = data.campaigns;
        if (data.totalRecords !== undefined) {
          this.totalItems = parseInt(data.totalRecords, 10);
        }
        this.totalPages - Math.ceil(this.totalItems / this.itemsPerPage);
        this.lastEvaluatedKey = data.lastEvaluatedKey;
        console.log("Current Page:" + this.currentPage)
        console.log('Total Items:', this.totalItems);
        console.log('Items Per Page:', this.itemsPerPage);
        console.log('LastEvaluatedKey:', this.lastEvaluatedKey);


      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchAnnouncement() {
      try {
        const userToken = (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken;

        const options = {
          method: 'GET',
          headers: {
            'Authorization': userToken
          },
        };

        const response = await fetch(`${BASE_URL}announcement`, options);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Response from announcement GET:", result);

        // Assuming 'message' is the property containing the text received from the backend
        this.editableText = result.message; 
      } catch (error) {
        console.error('Error while fetching announcement:', error);
      }
    },
    async toggleEdit() {
      this.isEditing = !this.isEditing;

      if (!this.isEditing) {
        console.log("Editing finished, new message is", this.editableText);

        const userToken = (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken;

        const requestBody = {
          message: this.editableText, 
        };

        const options = {
          method: 'POST',
          headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        };

        try {
          const response = await fetch(`${BASE_URL}announcement`, options);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
          console.log("Response from announcement update:", result);

          this.successMessage = 'Announcement updated successfully.';
        } catch (error) {
          console.error('Error while updating announcement:', error);
        }
      }
    },
    fetchPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.lastEvaluatedKey = this.previousLastEvaluatedKeys.pop();
        this.fetchData();
      }
    },
    fetchNextPage() {
      if (this.lastEvaluatedKey) {
        this.previousLastEvaluatedKeys.push(this.lastEvaluatedKey);
        this.currentPage++;
        this.fetchData();
    }
    },
    async resetStore() {
      await this.resetCampaignState();
    },
    getStatusClass(status) {
      switch (status) {
        case 'Pending':
          return 'orange';
        case 'Denied':
          return 'red';
        case 'Approved':
          return 'green';
        default:
          return '';
      }
    },
    updateSelectedStatus(event) {
      this.selectedStatus = event.target.value;
      this.currentPage = 1; 
      this.previousLastEvaluatedKeys = []; 
      this.fetchData(); 
    },
    async signOut() {
      try {
        this.clearSuccessMessage();
        await Auth.signOut();
        await this.resetCampaignState();
        this.$router.push({ name: 'CampaignDashboard' });
      } catch (error) {
        console.log('Sign Out Failed', error);
      }
    },
    closeSuccessMessage() {
      this.clearSuccessMessage();
    },
    goToCampaignDetails(campaignId) {
      this.$router.push({ name: 'CampaignSummary', params: { campaignId } });
    },
    goToEditor() {
      this.$router.push('/question-editor');
    },
    goToReview(campaignId) {
      this.$router.push({ name: 'ReviewCampaign', params: { campaignId } });
    },
  },
  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    this.role = this.user.signInUserSession.idToken.payload['cognito:groups']?.[0];
    if (!this.role || this.role != 'approvers') {
      this.role = 'campaignPlanner';
    }
    console.log("Initial campaigns data:", this.campaigns);
    this.fetchData();
    this.fetchAnnouncement()
    this.$nextTick(() => {
      // eslint-disable-next-line no-undef
      $(this.$refs.statusDropdown).dropdown({
        onChange:function(value) {
          this.selectedStatus = value;
        }
      })
    })
  }
};
</script>

<style scoped>

.mars-logo {
  height: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
}
.header-title {
  display: flex;
  align-items: center;
  font-family: 'Your Font', sans-serif;
  color: #004a99; 
  margin-top: -6px !important;
}

.header-container {
  margin-bottom: -10px;
}

.header-action {
  display: flex;
  flex-direction: column; 
  align-items: flex-end; 
}

.header-action .new-campaign-button {
  align-self: flex-end; 
  margin-right: 10px;
}

.header-action .sign-out-button {
  margin-left: 0px !important;
}
.header-action .ui.button {
  background-color: #004a99 !important; 
  color: white !important; 
}

.user-and-logout {
  display: flex; 
  align-items: center; 
  white-space: nowrap;
  flex-grow: 1;
  max-width: none;
}

.user-info {
  background-color: #EAA300; 
  color: #FFFFFF; 
  font-weight: normal; 
  display: flex; 
  align-items: center; 
}

.ui.basic.label {
  flex-grow: 1;
  background-color: #EAA300; color: white;
}

.user-name,
.user-role {
  margin-right: 5px; 
}

.message {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
}
.loading-segment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: white;

}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.header-action {
  display: flex;
  align-items: center;
}

.welcome-message {
  margin-top: 0;
}

.ui.icon.button {
  transition: opacity 0.3s ease;
}

.ui.icon.button:hover {
  opacity: 1;
}

.ui.fluid.search.selection.dropdown {
  z-index: 10000;
}

.label {
  width: 60%;
}

.status-pending {
  background-color: #DAA520; 
  color: white;
  font-weight: 300; 
}

.status-approved {
  background-color: #228B22; 
  color: white;
  font-weight: 300;
}

.status-denied {
  background-color: #B22222; 
  color: white;
  font-weight: 300;
}

.pagination .ui.button {
  background-color: #0060A0 !important; 
  border: none !important;
  padding: 10px 10px !important;
  margin: 0 5px !important;
}
</style>
