<template>
    <div>
        <div class="header-container">
            <div class="header-content">
                <div class="header-title ui huge header bold">Risk Assessment Tool</div>
            </div>
            <div class="header-action">
                <button @click="goHome" class="ui icon mini button">
                    <i class="home icon"></i>
                </button>
                <button @click="signOut" class="ui blue mini button">
                    <i class="sign-out icon"></i>
                </button>
            </div>
        </div>

        <div class="container">
            <div v-if="isLoading">Loading...</div>
            <div v-else>
                <div class="header">Concept Specifics: Influencer</div>

                <div class="form">
                    <div class="field" v-for="(field, index) in formFields" :key="index">
                        <label>{{ field }}</label>
                        <div class="ui input">
                            <input type="text" v-model="this.$store.state.campaign.influencerQuestions[field]" />
                        </div>
                    </div>
                    <input type="file" ref="mediaInput" style="display: none" multiple @change="handleMediaSelection">
                    <div v-if="this.$store.state.campaign.influencerMediaFiles.length === 0" class="ui placeholder segment">
                    <div class="ui icon header">
                        <i class="file video outline icon"></i>
                        No media is currently attached.
                    </div>
                    <button class="ui basic animated button" @click="attachMedia">
                        <div class="visible content">Add Media</div>
                        <div class="hidden content">
                        <i class="attach icon"></i>
                        </div>
                    </button>
                    </div>
                    <div v-if="this.$store.state.campaign.influencerMediaFiles.length" class="ui list">
                    <div v-for="(media, index) in this.$store.state.campaign.influencerMediaFiles" :key="index" class="item">
                        {{ media.name }}
                        <i class="delete icon" @click="deleteMedia(index)"></i>
                    </div>
                    </div>
                </div>
            </div>

                <div class="actions">
                    <div class="left-actions">
                        <button class="ui basic button" @click="previous">Cancel</button>
                    </div>
                    <div v-if="this.$store.state.campaign.influencerMediaFiles.length" class=".center-actions">
                      <button class="ui basic animated button" @click="attachMedia">
                        <div class="visible content">Add Media</div>
                        <div class="hidden content">
                          <i class="attach icon"></i>
                        </div>
                      </button>
                    </div>
                    <div class="right-actions">
                        <button :disabled="!fieldsFilledOutInfluencerQuestions" class="ui primary button"
                            @click="next">Next</button>
                    </div>
                </div>
            </div>
        </div>
</template>
  
  
<script>
import { nextPage, previousPage, BASE_URL } from '../helper.js';
import { Auth } from 'aws-amplify';
export default {
    data() {
        return {
            formFields: []
        }

    },
    computed: {
        fieldsFilledOutInfluencerQuestions() {
            return Object.values(this.$store.state.campaign.influencerQuestions).every(value => value !== '');
        },
    },
    mounted() {
        this.fetchQuestions();
    },
    methods: {
        async fetchQuestions() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const userToken = user.signInUserSession.idToken.jwtToken;
                let data = {};
                if (Object.keys(this.$store.state.campaign.influencerQuestions).length > 1) {
                    this.formFields = Object.keys(this.$store.state.campaign.influencerQuestions)
                } else {

                    const headers = {
                        'Authorization': userToken,
                        'Content-Type': 'application/json'
                    }

                    try {
                        const response = await fetch(`${BASE_URL}questions`, { headers });

                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }

                        data = await response.json(); 

                        console.log(data); 
                    } catch (error) {
                        console.error('There was an error!', error);
                    }
                    const influencerQuestions = data.questions.find(item => item.campaign_type == 'Influencer');
                    if (influencerQuestions) {
                        this.formFields = influencerQuestions.questions;

                        for (let field of influencerQuestions.questions) {
                            this.$store.commit('updateInfluencerQuestionsScope', { field: field, value: '' });
                        }
                    }
                }
            } catch (error) {
                console.log('error', error)
            }
        },
      attachMedia() {
        this.$refs.mediaInput.click()
        console.log(this.$store.state.campaign.influencerMediaFiles)
      },
      handleMediaSelection() {
        const files = event.target.files;
          for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const fileData = {
                name: files[i].name,
                type: files[i].type,
                content: e.target.result.split(',')[1]
              };
              this.$store.commit('ADD_INFLUENCER_MEDIA_FILE', fileData);
              console.log(this.$store.state.campaign.influencerMediaFiles);
            };
            reader.readAsDataURL(files[i]);
          }
        },
        deleteMedia(index) {
          const removedFile = this.$store.state.campaign.influencerMediaFiles[index];
          if (removedFile) {
            this.$store.state.campaign.influencerMediaFiles.splice(index, 1);
          }
        },
        next() {
            const selectedCampaignTypes = this.$store.state.campaign.selectedCampaignTypes;
            const currentCampaignType = 'Influencer';

            nextPage(selectedCampaignTypes, currentCampaignType, this.$router);
        },
        previous() {
            const selectedCampaignTypes = this.$store.state.campaign.selectedCampaignTypes;
            const currentCampaignType = 'Influencer';

            previousPage(selectedCampaignTypes, currentCampaignType, this.$router);
        },
        goHome() {
            this.$router.push({ name: 'CampaignDashboard' });
        },
        async signOut() {
            console.log('signoutmethod reached');
            try {
                this.$store.commit('clearSuccessMessage');
                await Auth.signOut();
                await this.$store.dispatch('resetCampaignState');  
                this.$router.push({ name: 'CampaignDashboard' });  
            } catch (error) {
                console.log("Sign Out Failed", error);
            }
        },
    }
}
</script>
  
<style scoped>
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f3f3f3;
    margin-top: 0px;
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.container {
    width: 100%;
}

.form {
    width: 80%;
    margin: 0 auto;
}

.field {
    width: 100%;
    margin-bottom: 20px;
}

.ui.input {
    width: 100%;
    margin-top: 10px;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.left-actions,
.right-actions,
.center-actions {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.left-actions {
    justify-content: flex-start;
}

.right-actions {
    justify-content: flex-end;
}

.ui.basic.animated.button {
  background-color: #FE2C55 !important;
  color: white !important;
  border-color: #FE2C55 !important;
}

</style>