<template>
  <div class="ui modal" ref="modal">
    <i class="close icon"></i>
    <div class="header">
      Approve Campaign
    </div>
    <div class="content">
      <form class="ui form">
        <div class="field">
          <input type="text" v-model="comment" placeholder="Add Approval Comment...">
        </div>
        <br/>
        <div class="field" v-for="(email, index) in localEmails" :key="index">
          <input type="email" v-model="localEmails[index]" placeholder="Enter email address...">
        </div>
      </form>
    </div>
    <div class="actions">
      <div class="ui button" @click="addEmailField">+</div>
      <div class="ui positive right labeled icon button" @click="acceptCampaign">
        OK<i class="checkmark icon"></i>
      </div>
      <div class="ui button" @click="close">Close</div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    emails: Array
  },
  data() {
    return {
      localEmails: [...this.emails],
      comment: null
    }
  },
  emits: ['update:modelValue', 'submit'],
  methods: {
    acceptCampaign() {
      this.$emit('submit',{
        'emails': this.localEmails,
        'comment': this.comment
      })
    },
    close() {
      this.$emit('update:modelValue', false)
    },
    addEmailField() {
      this.localEmails.push('');
    }
  },
  watch: {
    modelValue(newVal) {
      $(this.$refs.modal).modal(newVal ? 'show' : 'hide')
    },
    emails: {
      immediate: true,
      handler(newVal) {
        this.localEmails = [...newVal]
      }
    }
  },
  mounted() {
    import('semantic-ui-css/semantic.min.js').then(() => {
      $(this.$refs.modal).modal({
        closable: false,
        onApprove: () => {
          $(this.$refs.modal).modal('hide')
        }
      })
    })
  }
}
</script>
