import { createStore } from 'vuex';
import { Auth } from 'aws-amplify';

const store = createStore({
    state: {
        user: null,
        successMessage: null,
        campaign: {
            generalScope: {},
            campaignTypes: [],
            selectedCampaignTypes: [],
            currentCampaignType: null,
            paidQuestions: {},
            influencerQuestions: {},
            organicQuestions: {},
            paidMediaFiles: [],
            influencerMediaFiles: [],
            organicMediaFiles: [],
            testAndLearnQuestions: {},
            wrapUp: {},
        }
    },
    mutations: { //synchronous functions that change state of store,
        SET_USER(state, user) {
            state.user = user;
        },
        RESET_CAMPAIGN_STATE(state) {
            state.campaign.generalScope = {};
            state.campaign.campaignTypes = [];
            state.campaign.selectedCampaignTypes = [];
            state.campaign.currentCampaignType = null;
            state.campaign.paidQuestions = {};
            state.campaign.influencerQuestions = {};
            state.campaign.organicQuestions = {};
            state.campaign.paidMediaFiles = [];
            state.campaign.influencerMediaFiles = [];
            state.campaign.organicMediaFiles = [];
            state.campaign.testAndLearnQuestions = {};
            state.campaign.wrapUp = {};

        },
        REMOVE_SELECTED_CAMPAIGN_TYPE(state, campaignType) {
            const index = state.campaign.selectedCampaignTypes.indexOf(campaignType);
            if (index !== -1) {
                state.campaign.selectedCampaignTypes.splice(index, 1);
            }
        },
        SET_SELECTED_CAMPAIGN_TYPES(state, selectedCampaignTypes) {
            state.campaign.selectedCampaignTypes = selectedCampaignTypes;
        },
        SET_CURRENT_CAMPAIGN_TYPE(state, campaignType) {
            state.campaign.currentCampaignType = campaignType;
        },
        SET_GENERAL_SCOPE(state, generalScope) {
            state.campaign.generalScope = generalScope;
        },
        ADD_PAID_MEDIA_FILE(state, file) {
            state.campaign.paidMediaFiles.push(file)
        },
        CLEAR_PAID_MEDIA_FILES(state) {
            state.campaign.paidmediaFiles = []
        },
        ADD_INFLUENCER_MEDIA_FILE(state, file) {
            state.campaign.influencerMediaFiles.push(file)
        },
        CLEAR_INFLUENCER_MEDIA_FILES(state) {
            state.campaign.influencerMediaFiles = []
        },
        ADD_ORGANIC_MEDIA_FILE(state, file) {
            state.campaign.organicMediaFiles.push(file)
        },
        CLEAR_ORGANIC_MEDIA_FILES(state) {
            state.campaign.organicMediaFiles = []
        },
        setSuccessMessage(state, message) {
            state.successMessage = message;
        },
        clearSuccessMessage(state) {
            console.log('clearSuccessMessage mutation called');
            state.successMessage = null;
        },
        clearFlightTiming(state, payload) {
            if(state.campaign.generalScope[payload.field]) {
              state.campaign.generalScope[payload.field].start = null;
              state.campaign.generalScope[payload.field].end = null;
            }
        },
        updateCampaignTypes(state, payload) {
            state.campaign.campaignTypes = payload;
        },
        updateGeneralScope(state, payload) {
            state.campaign.generalScope = payload;
        },
        updateGeneralScopeField(state, payload) {
            Reflect.set(state.campaign.generalScope, payload.field, payload.value);
        },
        updatePaidQuestionsScope(state, payload) {
            state.campaign.paidQuestions[payload.field] = payload.value;
        },
        updateInfluencerQuestionsScope(state, payload) {
            state.campaign.influencerQuestions[payload.field] = payload.value;
        },
        updateOrganicQuestionsScope(state, payload) {
            state.campaign.organicQuestions[payload.field] = payload.value;
        },
        updateTestAndLearnScope(state, payload) {
            state.campaign.testAndLearnQuestions[payload.field] = payload.value;
        }

    },
    actions: { //asynchronous func that commit mutation, can also dispatch multiple actions
        //commit GeneralScope mutation
        async fetchUser({ commit }) {
            const user = await Auth.currentAuthenticatedUser();
            commit('SET_USER', user)

        },
        resetCampaignState({ commit }) {
            commit('RESET_CAMPAIGN_STATE');
        },
        addGeneralScope({ commit }, generalScope) {
            commit('updateGeneralScope', generalScope);
        },
        setGeneralScope({ commit }, generalScope) {
            commit('SET_GENERAL_SCOPE', generalScope);
        },
        addPaidQuestionsScope({ commit }, paidQuestions) {
            commit('updatePaidQuestionsScope', paidQuestions);
        },
        addInfluencerQuestionsScope({ commit }, influencerQuestions) {
            commit('updateInfluencerQuestionsScope', influencerQuestions)
        },
        addOrganicQuestionsScope({ commit }, organicQuestions) {
            commit('updateOrganicQuestionsScope', organicQuestions)
        },
        addTestAndLearnQuestionsScope({ commit }, testAndLearnQuestions) {
            commit('updateTestAndLearnScope', testAndLearnQuestions)
        },
        addCampaignType({ commit }, campaignTypes) {
            commit('updateCampaignTypes', campaignTypes)
        },
        addSelectedCampaignType({ commit }, selectedCampaignTypes) {
            commit('SET_SELECTED_CAMPAIGN_TYPES', selectedCampaignTypes);
        },
        removeSelectedCampaignType({ commit }, campaignType) {
            commit('REMOVE_SELECTED_CAMPAIGN_TYPE', campaignType);
        },
        setCurrentCampaignType({ commit }, campaignType) {
            commit('SET_CURRENT_CAMPAIGN_TYPE', campaignType);
        },
    },
    getters: {}
});

export default store;