<template>
  <div class="header-container">
     <div class="header-content">
        <div class="header-title ui huge header bold">Risk Assessment Tool</div>
     </div>
     <div class="header-action">
        <button @click="goHome" class="ui icon mini button">
        <i class="home icon"></i>
        </button>
        <button @click="signOut" class="ui blue mini button">
        <i class="sign-out icon"></i>
        </button>
     </div>
  </div>
  <div class="ui container">
    <div v-if="isLoading" class="loading-cover">
      <div class="ui active dimmer" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000;">
        <div class="ui text loader">Processing Campaign Information</div>
      </div>
    </div>
    <div v-else style="padding: 20px;">
      <div class="content-container" style="display: grid; gap: 20px;">
        <div class="questions-section">
          <div class="header">Modify Campaign</div>
          <div class="ui buttons mb-4">
            <button class="ui button" v-for="(item, index) in campaignInfo.response" :key="index"
              @click="selectCampaignType(item.campaign_type)">
              {{ item.campaign_type }}
            </button>
          </div>
          <div class="ui warning message">
            <div class="header">
              Please review all campaign questions before re-submitting your changes.
            </div>
          </div>
          <div v-for="(item, index) in campaignInfo.response" :key="index"
            v-show="item.campaign_type === selectedCampaignType" class="ui segment">
            <h2 class="ui header">{{ item.campaign_type }}</h2>
            <div class="ui form">
              <div class="field" v-for="(responseItem, rIndex) in item.response" :key="rIndex">
                <template v-if="responseItem.Question.toLowerCase().includes('flight/timing')">
                  <label>{{ responseItem.Question }}</label>
                  <div class="date-pickers">
                    <input type="date"
                          :value="parseDate(responseItem.Value, 'start')"
                          :min="today"
                          @input="handleStartDateChange(responseItem, $event.target.value)"
                          class="date-picker" />
                    <input type="date"
                          :value="parseDate(responseItem.Value, 'end')"
                          :min="parseDate(responseItem.Value, 'start')"
                          @input="handleEndDateChange(responseItem, $event.target.value)"
                          class="date-picker" />
                  </div>
                </template>
                <template v-else-if="responseItem.Question.toLowerCase() === 'region'">
                  <label>{{ responseItem.Question }}</label>
                  <div class="ui input">
                    <select v-model="responseItem.Value" class="ui fluid dropdown">
                      <option value="" disabled selected>Choose your region</option>
                      <option v-for="(regionName, regionCode) in regions" :key="regionName" :value="regionName">
                        {{ regionCode }}
                      </option>
                    </select>
                  </div>
                </template>
              <template v-else-if="responseItem.Question.toLowerCase() === 'budget'">
                <label>{{ responseItem.Question }}</label>
                <input type="text"
                      v-model="responseItem.Value"
                      @blur="validateBudget(responseItem)"
                      class="ui input" />
                <div v-if="responseItem.invalidBudget" class="ui red message">
                  Please enter a valid number for Budget
                </div>
              </template>
                <template v-else-if="responseItem.Question.toLowerCase().includes('agency email(s)')">
                  <label>{{ responseItem.Question }}</label>
                  <input type="email"
                        v-model="responseItem.Value"
                        @blur="validateEmail(responseItem)"
                        class="ui input" />
                        <div v-if="responseItem.invalidEmail" class="ui red message">
                          Please enter a valid email address
                        </div>
                </template>
                <template v-else>
                  <label>{{ responseItem.Question }}</label>
                  <input type="text"
                        v-model="responseItem.Value"
                        class="ui input" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isUploading" class="ui active inverted dimmer">
          <div class="ui text loader">Uploading</div>
        </div>
        <div v-if="campaignInfo[selectedCampaignType.toLowerCase() + 'MediaKeys']" class="media-section ui segment">
          <h3 class="ui dividing header">{{ selectedCampaignType }} Media</h3>
          <div class="media-gallery-container">
            <div v-for="(key, index) in campaignInfo[selectedCampaignType.toLowerCase() + 'MediaKeys'].concat(campaignInfo[selectedCampaignType.toLowerCase() + 'StagingMediaKeys'] || [])"
                :key="`media-${key}`" class="media-card">
              <img v-if="isImageUrl(key)" :src="getMediaUrl(key)" :alt="`Media item ${index}`" />
              <video v-else-if="isVideoUrl(key)" controls>
                <source :src="getMediaUrl(key)" :type="getMediaType(key)" />
                Your browser does not support the video tag.
              </video>
              <div class="media-caption">
                Media Item {{ index + 1 }}
                <button @click="deleteMedia(key, selectedCampaignType)"
                        class="ui red icon mini button media-delete-button">
                  <i class="trash icon"></i>
                </button>
              </div>
            </div>
          </div>
          <label for="file-upload" class="ui icon button media-upload-button">
            <i class="cloud upload icon"></i>
            <input type="file" id="file-upload" @change="uploadMedia($event, selectedCampaignType)" hidden>
          </label>
        </div>

           <h2 class="ui header">
              Campaign History
              <div class="sub header">View historic changes and comments to the campaign.</div>
           </h2>
           <table class="ui celled table" v-if="pastActions && pastActions.length">
              <thead>
                 <tr>
                    <th>Individual</th>
                    <th>Action</th>
                    <th>Date</th>
                    <th>Comment</th>
                 </tr>
              </thead>
              <tbody>
                 <tr v-for="action in pastActions" :key="action.id">
                    <td>{{ action.action_by_name }}</td>
                    <td>{{ action.action }}</td>
                    <td>{{ formatDate(action.updated_on) }}</td>
                    <td>{{ action.comment }}</td>
                 </tr>
              </tbody>
           </table>
        </div>
        <div class="buttons-container" style="display: flex; justify-content: center; margin-top: 20px;">
           <button class="ui red button" @click="goHome">Cancel</button>
           <button class="ui blue button"  @click="showModal"
              style="margin-left: 10px;">Submit Changes</button>
        </div>
     </div>
     <div id="confirmationModal" class="ui modal">
        <div class="header">Confirmation</div>
        <div class="content">
           <form class="ui form">
              <div class="field">
                 <input type="text" v-model="comment" placeholder="Describe your modifications.." required>
              </div>
           </form>
           <br/>
           <p>Are you sure you want to submit these changes?</p>
        </div>
        <div class="actions">
           <div class="ui negative button">No</div>
           <div class="ui positive button" @click="submitResponses">Yes</div>
        </div>
     </div>
  </div>
</template>

<script>
// eslint-disable-next-line 
import router from "@/router";
import { Auth } from "aws-amplify";
import localJQuery from 'jquery';
import 'semantic-ui-css/semantic.min.js';
import { BASE_URL } from '../helper.js';
import axios from 'axios'
import moment from 'moment'

const $ = localJQuery;

export default {
  async mounted() {
    let campaignIdFromRoute = this.$route.params.campaignId;

    if (!campaignIdFromRoute) {
      campaignIdFromRoute = localStorage.getItem('campaignId');
    }

    if (campaignIdFromRoute) {
      this.campaignId = campaignIdFromRoute;
      localStorage.setItem('campaignId', campaignIdFromRoute); 

      const localStorageData = localStorage.getItem(`campaignData-${this.campaignId}`);
      if (localStorageData) {
        try {
          this.response = JSON.parse(localStorageData);

          if (this.response && this.response.length > 0) {
            this.selectedCampaignType = this.response[0].campaign_type;
          }
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } 

      try {
        await Promise.all([
          this.fetchCampaignData(),
          this.fetchActions() 
        ]);
      } catch (error) {
        console.error("Error during initial data fetch:", error);
      } finally {
        this.isLoading = false;
      }
    } else {
      console.error("Error: campaignId is undefined.");
      this.isLoading = false;
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler() {
        const localStorageData = localStorage.getItem(`campaignData-${this.campaignId}`);
        if (localStorageData) {
          try {
            this.response = JSON.parse(localStorageData);

            if (this.response && this.response.length > 0) {
              this.selectedCampaignType = this.response[0].campaign_type;
            }
            this.isLoading = false;
          } catch (error) {
            console.error("Error parsing JSON from localStorage:", error);
            await this.fetchCampaignData();
          }
        } else {
          await this.fetchCampaignData();
        }
      }
    },
    response: {
      handler(newValue) {
        localStorage.setItem(`campaignData-${this.campaignId}`, JSON.stringify(newValue));
      },
      deep: true
    }
  },
  computed: {
  },
  data() {
    return {
      campaignId: null,
      campaignInfo: [],
      isLoading: true,
      selectedCampaignType: '',
      response: [],
      pastDenialReasons: [],
      campaignFetched: false,
      startDate: null,
      endDate: null,
      minEndDate: null,
      today: new Date().toISOString().slice(0, 10),
      comment: null,
      isUploading: false,
      selectedRegion: '',
      agencyEmails: '',
      regions: {
        US: 'United States',
        NA: 'North America (Not US)',
        EU: 'Europe',
        APAC: 'Asia Pacific',
        CNOB: 'China & North Asia',
        LATAM: 'Latin America',
        MEA: 'Middle East & Africa',
      }
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'CampaignDashboard' });
    },
    validateEmail(responseItem) {
      const emails = responseItem.Value.split(',');
      const emailPattern = /^\S+@\S+\.\S+$/; 
      let allValid = true;

      for (let i = 0; i < emails.length; i++) {
        if (!emailPattern.test(emails[i].trim())) {
          allValid = false;
          break; 
        }
      }

      if (!allValid) {
        responseItem.Value = ''; 
        responseItem.invalidEmail = true;
      } else {
        responseItem.invalidEmail = false;
      }
    },
    validateBudget(responseItem) {
      const isNumber = !isNaN(parseFloat(responseItem.Value)) && isFinite(responseItem.Value);
      
      if (!isNumber) {
        responseItem.Value = ''; 
        responseItem.invalidBudget = true;
      } else {
        responseItem.invalidBudget = false;
      } 
    },
    formatDate(unixTimestamp) {
      return moment.unix(unixTimestamp).format('MM/DD/YYYY');
    },
    isImageUrl(url) {
            console.log(url)
            return /\.(jpg|jpeg|png|gif)$/i.test(url.split('?')[0]);
    },
    isVideoUrl(url) {
        console.log(/\.(mp4|webm|ogg|mov|m4v)$/i.test(url.split('?')[0]))
        return /\.(mp4|webm|ogg|mov|m4v)$/i.test(url.split('?')[0]);
    },
    getMediaType(key) {
      const fileExtension = key.split('.').pop();
      switch(fileExtension) {
        case 'mp4':
          return 'video/mp4';
        case 'webm':
          return 'video/webm';
        default:
          return ''; 
      }
    },
    getMediaUrl(key) {
        const campaignTypeKey = this.selectedCampaignType.toLowerCase();
      
        if (this.campaignInfo && this.campaignInfo[`${campaignTypeKey}MediaKeysUrls`]) {
          if (this.campaignInfo[`${campaignTypeKey}StagingMediaKeys`]?.includes(key)) {
            return this.campaignInfo[`${campaignTypeKey}StagingMediaUrls`]?.[key] || 'path/to/default/image.png';
          } else {
            return this.campaignInfo[`${campaignTypeKey}MediaKeysUrls`]?.[key] || 'path/to/default/image.png';
          }
        } else {
          return 'path/to/default/image.png';
        }
      },
    gatherNewMediaKeysForSubmission() {
      const newMediaKeys = {
        paidNewMediaKeys: [],
        influencerNewMediaKeys: [],
        organicNewMediaKeys: [],
      };

      ['paid', 'influencer', 'organic'].forEach((mediaType) => {
        const stagingKeyProp = `${mediaType}StagingMediaKeys`;
        const newKeyProp = `${mediaType}NewMediaKeys`;

        (this.campaignInfo[stagingKeyProp] || []).forEach(stagingKey => {
          const normalizedKey = stagingKey.replace('staging/', '');
          newMediaKeys[newKeyProp].push(normalizedKey);
        });
      });
      console.log('New media keys gathered for submission:' + newMediaKeys);
      return newMediaKeys;
    },
    gatherDeletedMediaKeysForSubmission() {
      const deletedMediaKeys = {
        paidDeletedMediaKeys: this.campaignInfo.paidDeletedMediaKeys || [],
        influencerDeletedMediaKeys: this.campaignInfo.influencerDeletedMediaKeys || [],
        organicDeletedMediaKeys: this.campaignInfo.organicDeletedMediaKeys || [],
      };
      
      console.log("Deleted media keys gathered for submission:", deletedMediaKeys);
      return deletedMediaKeys;
    },
    deleteMedia(mediaKey, mediaType) {
      console.log("Attempting to delete media:", mediaKey, "from media type:", mediaType);

      const normalizedKey = mediaKey.replace('staging/', '');
      
      const typeMediaKeys = `${mediaType.toLowerCase()}MediaKeys`;
      const typeMediaKeysUrls = `${mediaType.toLowerCase()}MediaKeysUrls`;
      const typeStagingMediaKeys = `${mediaType.toLowerCase()}StagingMediaKeys`;
      const typeDeletedMediaKeys = `${mediaType.toLowerCase()}DeletedMediaKeys`;
      const typeNewMediaKeys = `${mediaType.toLowerCase()}NewMediaKeys`;  

      this.campaignInfo[typeDeletedMediaKeys] = this.campaignInfo[typeDeletedMediaKeys] || [];
      this.campaignInfo[typeNewMediaKeys] = this.campaignInfo[typeNewMediaKeys] || [];
      
      if (mediaKey.startsWith('staging/')) {
        const stagingIndex = this.campaignInfo[typeStagingMediaKeys]?.indexOf(mediaKey);
        if (stagingIndex > -1) {
          this.campaignInfo[typeStagingMediaKeys].splice(stagingIndex, 1);
        } else {
          console.warn("Staged media key not found:", mediaKey);
        }

        const newIndex = this.campaignInfo[typeNewMediaKeys].indexOf(mediaKey);
        if (newIndex > -1) {
          this.campaignInfo[typeNewMediaKeys].splice(newIndex, 1);
        }

      } else {
        const confirmIndex = this.campaignInfo[typeMediaKeys].indexOf(normalizedKey);
        if (confirmIndex > -1) {
          this.campaignInfo[typeMediaKeys].splice(confirmIndex, 1);
          delete this.campaignInfo[typeMediaKeysUrls][normalizedKey];

          this.campaignInfo[typeDeletedMediaKeys].push(normalizedKey);
        } else {
          console.warn("Confirmed media key not found:", normalizedKey);
        }
      }

      console.log(`Deleted media keys for ${mediaType}:`, this.campaignInfo[typeDeletedMediaKeys]);
    },
    async uploadMedia(event, campaignType) {
      const file = event.target.files[0];
      if (!file) return;

      this.isUploading = true;

      try {
        const mediaKeyPropertyName = `${campaignType.toLowerCase()}StagingMediaKeys`;
        const mediaUrlsPropertyName = `${campaignType.toLowerCase()}StagingMediaUrls`;
        const newMediaKeyPropertyName = `${campaignType.toLowerCase()}NewMediaKeys`;

        this.campaignInfo[mediaKeyPropertyName] = this.campaignInfo[mediaKeyPropertyName] || [];
        this.campaignInfo[mediaUrlsPropertyName] = this.campaignInfo[mediaUrlsPropertyName] || {};
        this.campaignInfo[newMediaKeyPropertyName] = this.campaignInfo[newMediaKeyPropertyName] || [];

        const presignedData = await this.getPresignedUrls(file.name, file.type, true);
        const uploadUrl = presignedData.presigned_url;
        const s3StagingMediaKey = `${presignedData.s3_campaign_key}`; 

        await this.putFileToS3(uploadUrl, file);

        const viewingUrls = await this.getMediaPresignedUrls([s3StagingMediaKey]);

        this.campaignInfo[mediaKeyPropertyName].push(s3StagingMediaKey);
        this.campaignInfo[mediaUrlsPropertyName][s3StagingMediaKey] = viewingUrls[s3StagingMediaKey];

        this.campaignInfo[newMediaKeyPropertyName].push(s3StagingMediaKey);

        this.isUploading = false;

        console.log(`New staged media key added for ${campaignType}: ${s3StagingMediaKey}`);
        console.log(`View URL for new staged media: ${viewingUrls[s3StagingMediaKey]}`);
        console.log('Updated campaignInfo with new staging media:', this.campaignInfo);
      } catch (error) {
        console.error('Error during the file upload process:', error);
        this.isUploading = false;
      }
    },
    async getMediaViewUrls(stagedMediaKeys) {
      const user = await Auth.currentAuthenticatedUser();
      const userToken = user.signInUserSession.idToken.jwtToken;

      try {
        const response = await axios.get(`${BASE_URL}campaigns/media-presigned-url`, {
          params: {
            mediaKeys: encodeURIComponent(stagedMediaKeys.join(',')),
            isStaging: true
          },
          headers: {
            'Authorization': userToken
          },
        });

        if (response.status === 200) {
          const responseBody = JSON.parse(response.data.body);
          console.log('Received view pre-signed URLs:', responseBody.preSignedUrls);
          return responseBody.preSignedUrls;
        } else {
          console.error(`Failed to retrieve view URLs: ${response.status}`);
          return {};
        }
      } catch (error) {
        console.error('Error getting media view presigned URLs:', error);
        throw error;
      }
    },
    async getPresignedUrls(fileName, fileType, isStaging = false) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userToken = user.signInUserSession.idToken.jwtToken; 
        
        const queryParams = isStaging ? { fileName, fileType, is_staging: true } : { fileName, fileType };
        console.log("Query Params:" + isStaging)
        const response = await axios.get(`${BASE_URL}campaigns/presigned-url`, {
          params: queryParams, 
          headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json',
          },
        });
        
        if (response.status === 200) {
          return JSON.parse(response.data.body);
        } else {
          throw new Error(`Received non-200 response status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error getting pre-signed URL:', error);
        throw error;
      }
    },
    async putFileToS3(preSignedUrl, file) {
      try {
        const response = await axios.put(preSignedUrl, file, {
          headers: {
            'Content-Type': file.type
          }
        });

        if (response.status === 200) {
          console.log(`File uploaded to S3 successfully: ${file.name}`);
          return { success: true };
        } else {
          console.error('File upload to S3 failed:', response.status);
          return { success: false };
        }
      } catch (error) {
        console.error('Error during file upload to S3:', error);
        return { success: false };
      }
    },
    initializeFlightDates() {
      
    },
    parseDate(value, part) {
      const dates = value.split(' - ');
      if (part === 'start' && dates.length > 0) {
        return dates[0];
      } else if (part === 'end' && dates.length > 1) {
        return dates[1];
      }
      return '';
    },
    handleStartDateChange(responseItem, newStartDate) {
      console.log("Initial start date: ", newStartDate);
      const endDate = this.parseDate(responseItem.Value, 'end');
      if (endDate && new Date(newStartDate) > new Date(endDate)) {
        responseItem.Value = `${newStartDate} - `;
      } else {
        responseItem.Value = `${newStartDate} - ${endDate}`;
      }
      console.log("Final start date: ", newStartDate);
    },

    handleEndDateChange(responseItem, newEndDate) {
      console.log("Initial end date: ", newEndDate);
      const startDate = this.parseDate(responseItem.Value, 'start');
      if (startDate && new Date(newEndDate) < new Date(startDate)) {
        responseItem.Value = ` - ${newEndDate}`;
      } else {
        responseItem.Value = `${startDate} - ${newEndDate}`;
      }
      console.log("Final end date: ", newEndDate);
    },
    beforeSubmit() {
      this.response.forEach(item => {
        item.response.forEach(responseItem => {
          if (responseItem.Question.toLowerCase().includes('flight/timing')) {
            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);
            const formattedStartDate = startDate.toISOString().slice(0, 10);
            const formattedEndDate = endDate.toISOString().slice(0, 10);

            responseItem.Value = `${formattedStartDate} - ${formattedEndDate}`;
            console.log("Value for 'flight/timing': ", responseItem.Value);
          }
          if (responseItem.Question.toLowerCase().includes('region')) {
                responseItem.Value = this.selectedRegion;
            }
          if (responseItem.Question.toLowerCase().includes('agency email(s)')) {
              responseItem.Value = this.agencyEmails;
          }
          
        });
      });
    },
    async signOut() {
      console.log('signoutmethod reached');
      try {
        this.$store.commit('clearSuccessMessage');
        await Auth.signOut();
        await this.$store.dispatch('resetCampaignState');
        this.$router.push({ name: 'CampaignDashboard' });
      } catch (error) {
        console.log("Sign Out Failed", error);
      }
    },
    selectCampaignType(campaignType) {
      this.selectedCampaignType = campaignType;
    },
    showModal() {
      $('#confirmationModal').modal('show');
    },
    async fetchCampaignData() {
    const user = await Auth.currentAuthenticatedUser();
    const userToken = user.signInUserSession.idToken.jwtToken; 
    const endPoint = `${BASE_URL}campaigns/` + this.campaignId;

    try {
      const response = await fetch(endPoint, {
        headers: {
          'Authorization': userToken,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      this.campaignFetched = true;

      this.campaignInfo = data; 

      for (const fileType of ['paidMediaKeys', 'influencerMediaKeys', 'organicMediaKeys']) {
        if (this.campaignInfo[fileType] && this.campaignInfo[fileType].length > 0) {
          const preSignedUrls = await this.getMediaPresignedUrls(this.campaignInfo[fileType]);
          this.campaignInfo[`${fileType}Urls`] = preSignedUrls;
        } else {
          this.campaignInfo[`${fileType}Urls`] = {};
        }
      }

      if (this.campaignInfo.response && this.campaignInfo.response.length > 0) {
        this.selectedCampaignType = this.campaignInfo.response[0].campaign_type;
      }

      if (this.selectedCampaignType) {
        const selectedResponse = this.campaignInfo.response.find(response => response.campaign_type === this.selectedCampaignType);
        if (selectedResponse) {
          selectedResponse.response.forEach(responseItem => {
            if (responseItem.Question.toLowerCase().includes('flight/timing')) {
              const dates = responseItem.Value.split(' - ');
              if (this.isValidDate(dates[0]) && this.isValidDate(dates[1])) {
                this.startDate = new Date(dates[0]).toISOString().slice(0, 10);
                this.endDate = new Date(dates[1]).toISOString().slice(0, 10);
              } else {
                this.startDate = null;
                this.endDate = null;
              }
            }
          });
        }
      }
      console.log(this.campaignInfo)
      this.isLoading = false;
    } catch (error) {
      console.error('Error during fetchCampaignData:', error);
    }
  },
  async submitResponses() {
    console.log("SUBMIT RESPONSES CALLED")
    this.beforeSubmit();
    console.log("Final campaign response data for submission:", this.campaignInfo);
    this.isLoading = true;
    const user = await Auth.currentAuthenticatedUser();
    const userToken = user.signInUserSession.idToken.jwtToken;
    const newMediaKeys = this.gatherNewMediaKeysForSubmission();
    const deletedMediaKeys = this.gatherDeletedMediaKeysForSubmission();
    const requestBody = {
    // Construct an array with the current campaign state and configuration
      response: this.campaignInfo.response.map(campaignType => ({
        campaign_type: campaignType.campaign_type,
        response: campaignType.response.map(q => ({
          Question: q.Question,
          Value: q.Value
        }))
      })),
      id: this.campaignId,
      comment: this.comment,
      newMedia: newMediaKeys,
      deletedMedia: deletedMediaKeys
    };

    console.log("Request body for submission:", requestBody);
    
    const options = {
      method: 'POST',
      headers: {
        'Authorization': userToken,
        'Content-Type': 'application/json'
      },
    };

    try {
      const response = await fetch(`${BASE_URL}campaigns/modify`, {
        ...options,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log("Response from modify campaign:", result);

      localStorage.removeItem('campaignData');
      $('#confirmationModal').modal('hide');
      this.$store.commit('setSuccessMessage', 'The campaign was modified and submitted successfully.');
      setTimeout(() => {
        this.$store.commit('clearSuccessMessage');
      }, 3000);
      this.$router.push({ name: 'CampaignDashboard'})
    } catch (error) {
      console.error('Error while submitting campaign modifications:', error);
    } finally {
      this.isLoading = false;
    }
  },
  isValidDate(d) {
    return !isNaN(Date.parse(d));
  },
  async getMediaPresignedUrls(mediaKeys) {
    console.log("getMediaPresign is entered")
    const user = await Auth.currentAuthenticatedUser();
    const userToken = user.signInUserSession.idToken.jwtToken;
    const queryString = `mediaKeys=${encodeURIComponent(mediaKeys.join(','))}`;
    const presignedUrlEndPoint = `${BASE_URL}campaigns/media-presigned-url?${queryString}`;
    try {
        const response = await axios.get(presignedUrlEndPoint, {
            headers: { 'Authorization': userToken },
        });

        if (response.status === 200) {
            const responseBody = JSON.parse(response.data.body);
            console.log('Received pre-signed URLs:', responseBody.preSignedUrls);
            console.log(this.campaignInfo);
            return responseBody.preSignedUrls; 
        } else {
            console.error(`Failed to retrieve pre-signed URLs: ${response.status}`);
            return {};
        }
        } catch (error) {
        console.error('Error while getting pre-signed URLs:', error);
        return {};
        }
  },
  async fetchActions() {
          const user = await Auth.currentAuthenticatedUser();
          const userToken = user.signInUserSession.idToken.jwtToken;
          const endPoint = `${BASE_URL}actions/${this.campaignId}`;

          try {
              const response = await axios.get(endPoint, {
                  headers: {
                      'Authorization': userToken,
                  },
              });

              if (!response.data || !response.data.actions) {
                  throw new Error('No actions data received.');
              }
              
              this.pastActions = response.data.actions;
          } catch (error) {
              console.error('error fetching actions data:', error);
          }
  },

  }
}
</script>

<style scoped>
.date-pickers {
  display: flex;
  gap: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f3f3f3;
  margin-top: 0px;
  margin-bottom: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.media-section {
  max-width: 100%; /* It prevents the carousel from overflowing the parent container. */
  overflow: hidden;

}

.media-gallery-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 10px;
}

.media-card {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  flex: 0 0 auto;
  width: 200px; 
  background: #fff;
  padding: 10px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
}

.media-card img, .media-card video {
  width: 100%; 
  height: auto; 
  max-height: 200px; 
}

.media-caption {
  text-align: center;
  margin-top: 0.5rem;
}

.ui.red.icon.button {
  margin-top: 10px;
}

.media-upload-button {
  display: block; 
  margin-top: 10px; 
}

</style>

