<template>
  <div v-if="isLoading" class="loading-cover">
    <div class="ui active dimmer" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000;">
      <div class="ui text loader">Loading</div>
    </div>
  </div>
  <div v-else>
    <div class="header-container">
      <div class="header-content">
        <div class="header-title ui huge header bold">Risk Assessment Tool</div>
      </div>
      <div class="header-action">
        <button @click="goHome" class="ui icon mini button">
          <i class="home icon"></i>
        </button>
        <button @click="signOut" class="ui blue mini button">
          <i class="sign-out icon"></i>
        </button>
      </div>
    </div>
    <div class="ui container" style="max-width: 1000px;">
      <div class="ui grid stackable">
        <div class="sixteen wide column">
          <div class="ui card fluid">
            <div class="content">
              <div class="header">Campaign Summary</div>
            </div>
            <div class="ui card fluid">
              <div class="content">
                <div class="header">General Scope</div>
              </div>
              <div class="content">
                <ul class="ui list custom-list">
                  <li v-for="(value, key) in generalScope" :key="key">
                    <b>{{ key }}:</b> {{ value }}
                  </li>
                </ul>
              </div>
            </div>
            <section v-for="(campaignType, index) in selectedCampaignTypes" :key="index">
              <div class="ui card fluid">
                <div class="content">
                  <div class="header">{{ campaignType }}</div>
                </div>
                <div class="content">
                  <ul class="ui list custom-list">
                    <li v-for="(value, question) in getQuestions(campaignType)" :key="question">
                      <b>{{ question }}:</b> {{ value }}
                    </li>
                  </ul>
                  <div v-if="campaignType === 'Paid' && this.$store.state.campaign.paidMediaFiles.length">
                    <h4>Media Files:</h4>
                    <ul class="ui list custom-list">
                      <li v-for="(file, index) in this.$store.state.campaign.paidMediaFiles" :key="index">
                        {{ file.name }}
                      </li>
                    </ul>
                  </div>
                  <div v-if="campaignType === 'Influencer' && this.$store.state.campaign.influencerMediaFiles.length">
                    <h4>Media Files:</h4>
                    <ul class="ui list custom-list">
                      <li v-for="(file, index) in this.$store.state.campaign.influencerMediaFiles" :key="index">
                        {{ file.name }}
                      </li>
                    </ul>
                  </div>
                  <div v-if="campaignType === 'Organic' && this.$store.state.campaign.organicMediaFiles.length">
                    <h4>Media Files:</h4>
                    <ul class="ui list custom-list">
                      <li v-for="(file, index) in this.$store.state.campaign.organicMediaFiles" :key="index">
                        {{ file.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <div class="ui card fluid">
              <div class="content">
                <div class="header">Test & Learn</div>
              </div>
              <div class="content">
                <ul class="ui list custom-list">
                  <li v-for="(value, key) in testAndLearnQuestions" :key="key">
                    <b>{{ key }}:</b> {{ value }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button class="ui basic button" @click="previousPage">Cancel</button>
          <button class="ui primary button" @click="submitCampaign">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import { useStore } from 'vuex'
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../helper.js';

export default {
  data() {
    return {
      showModal: false,
      denialReason: '',
      isLoading: false
    }
  },
  setup() {
    const store = useStore();
    const generalScope = store.state.campaign.generalScope;
    const selectedCampaignTypes = store.state.campaign.selectedCampaignTypes;
    const testAndLearnQuestions = store.state.campaign.testAndLearnQuestions;
    const getQuestions = (campaignType) => {
      switch (campaignType) {
        case 'Paid':
          return store.state.campaign.paidQuestions;
        case 'Influencer':
          return store.state.campaign.influencerQuestions;
        case 'Organic':
          return store.state.campaign.organicQuestions;
        default:
          return {};
      }
    }

    console.log(store.state.campaign)

    return {
      generalScope,
      selectedCampaignTypes,
      getQuestions,
      testAndLearnQuestions
    }
  },
  methods: {
    previousPage() {
      this.$router.push({ name: 'TestAndLearn' })
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    submitDenialLog() {
      this.closeModal();
    },
    constructRequestBody() {
      let requestBody = {
        response: [],
        createdAt: new Date().toISOString()
      };
      let campaignTypesMap = {
        paidQuestions: "Paid",
        generalScope: "General Scope",
        influencerQuestions: "Influencer",
        organicQuestions: "Organic",
        testAndLearnQuestions: "Test and Learn"
      };

      for (let key in campaignTypesMap) {
        let campaignItem = {
          response: [],
          campaign_type: campaignTypesMap[key]
        };

        let campaignData = this.$store.state.campaign[key];
        for (let question in campaignData) {
          let questionResponse = {
            Value: campaignData[question],
            Question: question
          };
          campaignItem.response.push(questionResponse);
        }

        if (campaignItem.response.length >= 1)
          requestBody.response.push(campaignItem);
      }

      return requestBody;
    },
    async submitCampaign() {
      this.isLoading = true;

      try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;

        const headers = {
          'Authorization': token,
        };

        const uploadedMediaKeys = {
          paidMediaFiles: [],
          influencerMediaFiles: [],
          organicMediaFiles: []
        };
        console.log("uploaded Media Keys before blob and all that: " + uploadedMediaKeys)

        const base64ToBlob = (base64, mimeType) => {
          const byteCharacters = atob(base64);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          return new Blob(byteArrays, { type: mimeType });
        };

        const uploadFile = async (file, keyType) => {
          const blobData = base64ToBlob(file.content, file.type);
          console.log(`Uploading ${file.name} of type ${file.type}...`);

          // Retrieve the pre-signed URL and S3 campaign key from your backend
          const presignedUrlResponse = await axios.get(`${BASE_URL}campaigns/presigned-url`, {
            params: { fileName: file.name, fileType: file.type },
            headers: headers
          });
          
          // Parse the response body to get the pre-signed URL and S3 campaign key
          const responseBody = JSON.parse(presignedUrlResponse.data.body);
          const presignedUrl = responseBody.presigned_url;
          const s3CampaignKey = responseBody.s3_campaign_key;

          // Log the received pre-signed URL and S3 campaign key
          console.log(`Received pre-signed URL for ${file.name}:`, presignedUrl);
          console.log(`Received S3 campaign key for ${file.name}:`, s3CampaignKey);

          // Upload the file using the pre-signed URL
          await axios.put(presignedUrl, blobData, { headers: { 'Content-Type': file.type } });
          console.log(`File ${file.name} uploaded successfully.`);
          
          // Push the S3 campaign key to the corresponding array in uploadedMediaKeys
          uploadedMediaKeys[keyType].push(s3CampaignKey);
          console.log(`Stored S3 campaign key for ${file.name}:`, s3CampaignKey);
        };
        
        // Loop through all media file arrays and upload files
        for (const type of ['paidMediaFiles', 'influencerMediaFiles', 'organicMediaFiles']) {
          for (const file of this.$store.state.campaign[type]) {
            try {
              await uploadFile(file, type);
            } catch (uploadError) {
              console.error(`Error uploading ${file.name}:`, uploadError);
            }
          }
        }
        console.log('All files have been uploaded:', uploadedMediaKeys);

        const requestBody = this.constructRequestBody();

        requestBody.mediaKeys = {
          paidMediaFiles: uploadedMediaKeys.paidMediaFiles,
          influencerMediaFiles: uploadedMediaKeys.influencerMediaFiles,
          organicMediaFiles: uploadedMediaKeys.organicMediaFiles
        };

        console.log("Media Keys Uploaded: " + requestBody.mediaKeys);
        console.log(requestBody)
        console.log("requestToken is: " + user.signInUserSession.accessToken.jwtToken)
        const createCampaignResponse = await axios.post(`${BASE_URL}campaigns/create`, requestBody, {
          headers: {
            'Authorization': user.signInUserSession.idToken.jwtToken
          }
        });
        console.log("Campaign created:", createCampaignResponse.data);

        this.$router.push({ name: 'CampaignDashboard' });
      } catch (error) {
        console.error('Error during the upload:', error);
      } finally {
        this.isLoading = false;
      }
    },
    goHome() {
      this.$router.push({ name: 'CampaignDashboard' });
    },
    async signOut() {
      console.log('signoutmethod reached');
      try {
        this.$store.commit('clearSuccessMessage');
        await Auth.signOut();
        await this.$store.dispatch('resetCampaignState');  
        this.$router.push({ name: 'CampaignDashboard' });  
      } catch (error) {
        console.log("Sign Out Failed", error);
      }
    }
  }
  }
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f3f3f3;
  margin-top: 0px;
  margin-bottom: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}

ul.custom-list {
  list-style-type: none !important;
  padding-left: 0 !important;
}
ul.custom-list li {
  text-align: left !important;
}
.ui.list.custom-list > li {
  text-align: left !important;
}

</style>
  
