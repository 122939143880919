/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:875cd9ee-863d-4255-9cdd-4f94058ff3d8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_auMsyLmCM",
    "aws_user_pools_web_client_id": "k56j7nhdpfcn9bn6elq2je5n4",
    "oauth": {
        "domain": "automationapproval.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "marsriskassessmenttoolv2-storage-b0bb44e7155238-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "ars2ew-20240305101936-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d179xa2of95ays.cloudfront.net"
};


export default awsmobile;
